#container {
    padding: 0;
    font-size: 12px;
    font-weight: bold;
}

#container .badge {
    font-size: 12px;
}

.info-text {
    white-space: pre;
}

.hide-player-cards {
    display: none;
}

#players::-webkit-scrollbar {
    display: none;
}

#players {
    overflow-y: scroll;
    max-height: 46vh;
    -ms-overflow-style: none;
}

#non-players {
    max-height: calc(50vh - 40px);
    max-height: -o-calc(50vh - 40px);
    max-height: -webkit-calc(50vh - 40px);
    max-height: -moz-calc(50vh - 40px);
}

@media (min-width: 576px) {
    #players {
        overflow-y: scroll;
        max-height: calc(100vh - 40px);
        max-height: -o-calc(100vh - 40px);
        max-height: -webkit-calc(100vh - 40px);
        max-height: -moz-calc(100vh - 40px);
    }
}

@media (max-width: 768px) {
    #container {
        width: 100%;
        max-width: none;
    }
}

.shrink-button-text {
    font-size: 12px !important;
}
