@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

body {
    background-image: linear-gradient(176deg, #f9f9f9 40%, #f0f0f0 40%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.App {
    text-align: center;
}

.nav-space {
    height: 100px;
}

.title-container {
    font-family: 'Lato', sans-serif;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}

.title {
    font-size: 64px;
    font-weight: bold;
}

.title-space {
    height: 50px;
}

.subtitle {
    font-size: 30px;
}

.section {
    font-size: 30px;
    font-weight: bold;
}

.section-text {
    font-size: 20px;
}

.symbols {
    font-size: 4em;
    font-weight: bold;
}

.playcard {
    width: 100px;
    height: auto;
}

.hand0 {
    transform: translateX(40px) rotate(-5deg);
}

.hand1 {
    transform: translateX(-40px) rotate(5deg);
}

.hand2 {
    transform: translateX(40px) rotate(-5deg);
}

.hand3 {
    transform: translateX(-40px) rotate(5deg);
}

.backs0 {
    transform: translateX(50px) rotate(-2deg);
}

.backs1 {
    transform: translateX(-50px) rotate(2deg);
}

.backs2 {
    transform: translateX(-100px) rotate(2deg);
}

.top-alert {
    margin-bottom: 0;
    border-radius: 0;
}
