.disable-touch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.rc-slider {
    height: 24px !important;
    padding: 10px 0 !important;
}

.rc-slider-handle {
    background-color: #007bff !important;
    border: solid 2px #007bff !important;
    width: 24px !important;
    height: 24px !important;
    margin-top: -10px !important;
}

.rc-slider-rail {
    background-color: #808080 !important;
}

.rc-slider-track {
    background-color: #007bff !important;
}

.rc-slider-vertical {
    height: 24px !important;
}

.emoji-recolor-white {
    color: transparent;
    text-shadow: 0 0 0 white;
}
