.list-group-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.player-list {
    overflow: hidden;
}

.shrink-players-text {
    font-size: 11px;
}

@keyframes pop {
    0% {
        transform: scale(2);
    }
    100% {
        transform: scale(1);
    }
}

.pop {
    animation: pop 0.2s linear 1;
}

@keyframes pulse {
    50% {
        transform: scale(1.05);
    }
}

.pulse {
    animation: pulse 0.2s linear 1;
}

@keyframes current {
    100% {
        transform: translateX(3px);
    }
}

.current {
    animation: current 0.3s linear 0s infinite alternate;
}

@keyframes decrease {
    0% {
        color: red;
    }
    80% {
        color: red;
    }
    100% {
        color: none;
    }
}

.decrease {
    animation: decrease 3s linear 1;
}

@keyframes increase {
    0% {
        color: green;
    }
    80% {
        color: green;
    }
    100% {
        color: none;
    }
}

.increase {
    animation: increase 3s linear 1;
}
