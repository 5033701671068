@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Averia+Serif+Libre&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    background-image: linear-gradient(176deg, #f9f9f9 40%, #f0f0f0 40%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.App {
    text-align: center;
}

.nav-space {
    height: 100px;
}

.title-container {
    font-family: 'Lato', sans-serif;
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
}

.title {
    font-size: 64px;
    font-weight: bold;
}

.title-space {
    height: 50px;
}

.subtitle {
    font-size: 30px;
}

.section {
    font-size: 30px;
    font-weight: bold;
}

.section-text {
    font-size: 20px;
}

.symbols {
    font-size: 4em;
    font-weight: bold;
}

.playcard {
    width: 100px;
    height: auto;
}

.hand0 {
    -webkit-transform: translateX(40px) rotate(-5deg);
            transform: translateX(40px) rotate(-5deg);
}

.hand1 {
    -webkit-transform: translateX(-40px) rotate(5deg);
            transform: translateX(-40px) rotate(5deg);
}

.hand2 {
    -webkit-transform: translateX(40px) rotate(-5deg);
            transform: translateX(40px) rotate(-5deg);
}

.hand3 {
    -webkit-transform: translateX(-40px) rotate(5deg);
            transform: translateX(-40px) rotate(5deg);
}

.backs0 {
    -webkit-transform: translateX(50px) rotate(-2deg);
            transform: translateX(50px) rotate(-2deg);
}

.backs1 {
    -webkit-transform: translateX(-50px) rotate(2deg);
            transform: translateX(-50px) rotate(2deg);
}

.backs2 {
    -webkit-transform: translateX(-100px) rotate(2deg);
            transform: translateX(-100px) rotate(2deg);
}

.top-alert {
    margin-bottom: 0;
    border-radius: 0;
}

#container {
    padding: 0;
    font-size: 12px;
    font-weight: bold;
}

#container .badge {
    font-size: 12px;
}

.info-text {
    white-space: pre;
}

.hide-player-cards {
    display: none;
}

#players::-webkit-scrollbar {
    display: none;
}

#players {
    overflow-y: scroll;
    max-height: 46vh;
    -ms-overflow-style: none;
}

#non-players {
    max-height: calc(50vh - 40px);
    max-height: -o-calc(50vh - 40px);
    max-height: -moz-calc(50vh - 40px);
}

@media (min-width: 576px) {
    #players {
        overflow-y: scroll;
        max-height: calc(100vh - 40px);
        max-height: -o-calc(100vh - 40px);
        max-height: -moz-calc(100vh - 40px);
    }
}

@media (max-width: 768px) {
    #container {
        width: 100%;
        max-width: none;
    }
}

.shrink-button-text {
    font-size: 12px !important;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    background-color: transparent;
    -webkit-perspective: 1000px;
            perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

@-webkit-keyframes turn {
    100% {
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }
}

@keyframes turn {
    100% {
        -webkit-transform: rotateY(180deg);
                transform: rotateY(180deg);
    }
}

.flip-card-inner-slow {
    -webkit-animation: turn 0.6s forwards;
            animation: turn 0.6s forwards;
    /* animation-delay: 0.6s; */
}

.flip-card-inner-fast {
    -webkit-animation: turn 0.01s forwards;
            animation: turn 0.01s forwards;
    /* animation-delay: 0.01s; */
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.not-used {
    opacity: 0.2;
}

.card-hidden {
    display: none;
}

.disable-touch {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

.rc-slider {
    height: 24px !important;
    padding: 10px 0 !important;
}

.rc-slider-handle {
    background-color: #007bff !important;
    border: solid 2px #007bff !important;
    width: 24px !important;
    height: 24px !important;
    margin-top: -10px !important;
}

.rc-slider-rail {
    background-color: #808080 !important;
}

.rc-slider-track {
    background-color: #007bff !important;
}

.rc-slider-vertical {
    height: 24px !important;
}

.emoji-recolor-white {
    color: transparent;
    text-shadow: 0 0 0 white;
}

.list-group-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.player-list {
    overflow: hidden;
}

.shrink-players-text {
    font-size: 11px;
}

@-webkit-keyframes pop {
    0% {
        -webkit-transform: scale(2);
                transform: scale(2);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes pop {
    0% {
        -webkit-transform: scale(2);
                transform: scale(2);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.pop {
    -webkit-animation: pop 0.2s linear 1;
            animation: pop 0.2s linear 1;
}

@-webkit-keyframes pulse {
    50% {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
}

@keyframes pulse {
    50% {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
}

.pulse {
    -webkit-animation: pulse 0.2s linear 1;
            animation: pulse 0.2s linear 1;
}

@-webkit-keyframes current {
    100% {
        -webkit-transform: translateX(3px);
                transform: translateX(3px);
    }
}

@keyframes current {
    100% {
        -webkit-transform: translateX(3px);
                transform: translateX(3px);
    }
}

.current {
    -webkit-animation: current 0.3s linear 0s infinite alternate;
            animation: current 0.3s linear 0s infinite alternate;
}

@-webkit-keyframes decrease {
    0% {
        color: red;
    }
    80% {
        color: red;
    }
    100% {
        color: none;
    }
}

@keyframes decrease {
    0% {
        color: red;
    }
    80% {
        color: red;
    }
    100% {
        color: none;
    }
}

.decrease {
    -webkit-animation: decrease 3s linear 1;
            animation: decrease 3s linear 1;
}

@-webkit-keyframes increase {
    0% {
        color: green;
    }
    80% {
        color: green;
    }
    100% {
        color: none;
    }
}

@keyframes increase {
    0% {
        color: green;
    }
    80% {
        color: green;
    }
    100% {
        color: none;
    }
}

.increase {
    -webkit-animation: increase 3s linear 1;
            animation: increase 3s linear 1;
}

.top-navbar {
    height: 40px;
    background-color: #2f343f;
}

.brand {
    font-family: 'Averia Serif Libre', cursive;
    font-weight: bold;
    font-size: 16px !important;
    color: #f0f0f0 !important;
}

.navbar-info {
    font-size: 11px;
    font-weight: bold;
    overflow: hidden;
    color: #f0f0f0 !important;
}

a {
    text-decoration: none !important;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.2s;
    transition: 0.2s;
}

input:checked + .slider {
    background-color: #007bff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
}

input:disabled + .slider {
    background-color: lightgrey;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

code {
    font-size: 30px !important;
}

