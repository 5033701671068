@import url('https://fonts.googleapis.com/css?family=Averia+Serif+Libre&display=swap');

.top-navbar {
    height: 40px;
    background-color: #2f343f;
}

.brand {
    font-family: 'Averia Serif Libre', cursive;
    font-weight: bold;
    font-size: 16px !important;
    color: #f0f0f0 !important;
}

.navbar-info {
    font-size: 11px;
    font-weight: bold;
    overflow: hidden;
    color: #f0f0f0 !important;
}

a {
    text-decoration: none !important;
}
